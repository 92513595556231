module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156010185-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"optimizeId":"YOUR_GOOGLE_OPTIMIZE_TRACKING_ID","experimentId":"YOUR_GOOGLE_EXPERIMENT_ID","variationId":"0","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"example.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"steinofen.rocks","short_name":"steinofen","description":"Dein Lego® Blog der rockt. News, Angebote, Reviews und mehr.","lang":"de","display":"standalone","icon":"src/images/steinofenrocks-logo-512.png","start_url":"/","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"brickoven.rocks","short_name":"brickoven","description":"Your Lego® blog that rocks. News, offers, reviews and more."}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
